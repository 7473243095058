<mat-list *ngIf="isLoading" class="sidebar-menu">
  <mat-list-item  class="skeleton-group">
    @for (i of [].constructor(12); track i){ 
      <div class="item skeleton line"></div>
      <br>
    }
  </mat-list-item>
</mat-list>

<mat-list *ngIf="!isLoading" class="sidebar-menu" [class.collapsed]="collapsed">
  <div #sidebar *ngIf="org">
    <ng-container *ngFor="let category of menuItems">
      <div class="sidebar-category">
          <div class="label" *ngIf="!collapsed">{{ category.category | translate | uppercase }}</div>
          <div class="divider" *ngIf="collapsed">
            <mat-divider></mat-divider>
          </div>
      </div>
      <ng-container *ngFor="let item of category.items">
        <mat-list-item *ngIf="item.condition === undefined || item.condition"
                       class="menu-item icon-button"
                       [routerLink]="[item.link]"
                       [matTooltip]="collapsed ? (item.tooltip | translate | titlecase) : null"
                       [matTooltipPosition]="'left'"
                       [class]="item.testid"
                       [attr.data-testid]="item.testid"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: false }">
          <mat-icon matListItemIcon
                    [inline]="true"
                    [svgIcon]="item.icon"
                    class="svg-icon"></mat-icon>
          <div matListItemTitle class="menu-item-title">{{ item.title | translate | titlecase }}</div>
        </mat-list-item>
      </ng-container>
    </ng-container>
  </div>
</mat-list>
